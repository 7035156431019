
// COMPONENTS

// APP
import { Actions } from "@/store/enums/StoreEnums";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, Field, Form } from "vee-validate";

interface AccessDetails {
	name: string;
	email: string;
	phone: string;
}

export default defineComponent({
	name: "ProjectAccess",
	components: {
		ErrorMessage,
		Field,
		Form,
	},
	props: {
		projectId: {
			type: String,
			required: true,
		},
		currentUser: Object,
	},
	setup(props) {
		
		const store = useStore();
		const route = useRoute();
		const router = useRouter();
		let loadingSecondaryForm = ref(true);

		let project = ref(null);
		const submitButtonAccess = ref<HTMLElement | null>(null);

		const accessDetails = ref<AccessDetails>({
			name: "",
			email: "",
			phone: "",
		});

		/*const accessValidator = Yup.object().shape({
			name: Yup.string().required().label("Name").nullable(),
			email: Yup.string().required().email().nullable(),
			phone: Yup.string().min(5).max(50).required().label("Phone").nullable(),
		});*/

		onMounted(async () => {
			//setCurrentPageTitle("Access");
			
			let projectItem = await store.dispatch(Actions.PROJECTS_ITEM_BY_ID, props.projectId );
			
			if (projectItem != undefined  && projectItem.code == 200) {
				let em = JSON.parse(projectItem.data.columns.dup__of_requester_email.value);
				let ph = JSON.parse(projectItem.data.columns.dup__of_requester_phone.value);
				let nm = JSON.parse(projectItem.data.columns.dup__of_requester_name.value);
				if (typeof(nm) !== 'undefined' && nm !== null) {
					nm = nm.replace(/"/g, '');
				}
				if (typeof(em) !== 'undefined' && em !== null) {
					em =  em.email.replace(/"/g, '');
				}else {
					em = '';
				}
				if (typeof(ph) !== 'undefined' && ph !== null) {
					ph =  ph.phone.replace(/"/g, '');
				}else {
					ph = '';
				}
				accessDetails.value.name = nm;
				accessDetails.value.email = em;
				accessDetails.value.phone = ph;
			} else {
				// NO USER FOUND, RETURN BACKWARD
				router.go(-1);
			}

			loadingSecondaryForm.value = false;
		});

		const showAlert = (icon, message) =>  {
			Swal.fire({
				text: message,
				icon: icon,
				confirmButtonText: "Ok",
				buttonsStyling: false,
				customClass: {
					confirmButton: "btn btn-light-primary",
				},
			});
		};

		const saveAccess = async (values) => {
			if (submitButtonAccess.value) {
				// Activate indicator
				submitButtonAccess.value.setAttribute("data-kt-indicator", "on");

				values.item_id = props.projectId;

				ApiService.setHeader();
				const resp = await ApiService.post("monday/board/item/access/secondary", values)
					.then(({ data }) => {
						showAlert('success', 'Successfully updated secondary requester');
						submitButtonAccess.value?.removeAttribute("data-kt-indicator");
						console.log(data);
						return true;
					})
					.catch(({ response }) => {
						//console.log(response);
					});
			}
		};

		return {
			project,
			saveAccess,
			//accessValidator,
			accessDetails,
			submitButtonAccess,
			loadingSecondaryForm
		};
	},
});
